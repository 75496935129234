<template>
  <div class="MainView">
    <div class="con_left">
      <img src="@/assets/login_icon1.png" class="login_icon" alt="">
      <div class="left_box">
        <img src="@/assets/login_total.png" alt="">
        <!-- <h2>国内领先的</h2>
        <span>校园大数据心理服务机构</span>
        <ul>
          <li v-for="(item, index) in conList" :key="index + 's1'">
            <h4>{{item.title}}</h4>
            <img :src="require(`@/assets/login_icon${2 + index}.png`)" alt="">
            <span>{{item.subTitle}}</span>
          </li>
        </ul>
        <div class="left_foot">
          <img src="@/assets/phone.png" alt="">
          <span>如有问题可以通过拨打010-82331300联系我们</span>
        </div> -->
      </div>
    </div>
    <div class="con_right">
      <div class="right_warp">
        <h2>欢迎来到</h2>
        <h2 style="margin: 8px 0px 24px;">绿萝心理云平台</h2>
        <span class="title">请选择你的登录方式</span>
        <ul style="marginTop: 32px;">
          <li v-for="(item, index) in clickCon" :key="index + 's2'" @click="clickHandle(index)">
            <img :src="require(`@/assets/login_tu${1 + index}.png`)" alt="">
            <h4>{{item}}</h4>
          </li>
        </ul>
        <div class="remark">
          <p>
            <a href="https://www.lvluoxinli.com/static/html/guide.html" target="_blank" style="color:#999999">用户服务指南</a>&nbsp;&nbsp;
            <a href="https://www.lvluoxinli.com/static/html/clause.html" target="_blank" style="color:#999999">使用条款和隐私政策</a>&nbsp;&nbsp;
            服务邮箱：lvluoxinli@dingtalk.com
          </p>
          <p>
            ©2020 北京心数矩阵科技有限公司版权所有&nbsp;&nbsp;
            <a href="https://beian.miit.gov.cn/" target="_blank" style="color:#999999">京ICP备18051758号-2</a> &nbsp;&nbsp;
            <i class="jgwablogo"></i>
            <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010202008381" target="_blank" style="color:#999999">京公网安备11010202008381</a>
          </p>
        </div>
      </div>

    </div>
    <!-- <div class="logo">
      <img v-if="!img" src="@/assets/logo.png" alt="">
      <img v-else style="width: 300px" :src='img' alt="图片">
    </div>
    <div class="content">
      <div  class="greeting" v-if="env.VUE_APP_AGENT_ID === 'yangguangxiaoyuan'" v-bind:style="{ color: activeColor, 'font-weight': fontSize }" >
        <span>京师心育 阳光校园</span>
        <br>
        <span>中小学生心理健康教育服务平台
      </span>
      </div>
      <div class="greeting" v-else>
        <span>欢迎来到，{{env.VUE_APP_AGENT_STSTEM_NAME ? env.VUE_APP_AGENT_STSTEM_NAME + '平台' : titleword}}</span>
      </div>
      <div class="choose">
        <span>请选择你的登录方式</span>
      </div>
      <div class="main">
        <div class="login"  v-on:click="open_client('eduadmin')">
          <img src="@/assets/jiaoweiduan.png" alt="">
        </div>
        <div class="login" v-on:click= "open_client('school')">
          <img src="@/assets/jiaoshiduan.png" alt="">
        </div>
        <div class="login" v-on:click= "open_client('student')">
          <img src="@/assets/xueshengduan.png" alt="">
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'MainView',
  data () {
    return {
      env: process.env,
      activeColor: 'green',
      fontSize: 700,
      img: null,
      titleword:'家校社协同心理关爱平台',
      qudao: ['ncmhc.lvluoxinli.com'],
      conList: [
        {title: '当前覆盖', subTitle: '省区市'},
        {title: '全国', subTitle: '运营中心'},
        {title: '累计服务', subTitle: '政府、学校客户'},
        {title: '累计服务', subTitle: '人次学生'}
      ],
      clickCon: ['教育局登录', '学校登录', '学生登录', '医生登录']
    }
  },
  mounted () {
    let url = window.location.host
    if (this.qudao.includes(url)) {
      this.educatorAdminLogin(url)
    }
  },
  methods: {
     async educatorAdminLogin(url) { // 登录
      let response = await axios.get(`https://api.lvluoxinli.com/nacos/v1/cs/configs?tenant=74a84264-72f5-448e-868d-c9fb00be9d25&dataId=${url}&group=qudao`)
      const res = response.data
      this.img = res.logo_url1
      this.titleword = res.qudao_name
    },
    open_client(c) {
      let doamin=this.env.VUE_APP_AGENT_domain || 'https://www.lvluoxinli.com' ;
      let path= this.env.VUE_APP_AGENT_PATH || ''
      switch (c) {
        case 'eduadmin':
           window.location.href=`${doamin}${path}/xsjzEducationPc/index.html#/`
          break;
        case 'school':
          window.location.href=`${doamin}${path}/teacherPC20/index.html#/`
          break;
        case 'student':
          if (doamin == 'https://ncmhc.lvluoxinli.com') {
            window.location.href=`${doamin}${path}/keti/stuPC/index.html#/`
          } else {
            window.location.href=`${doamin}${path}/stuPC/index.html#/`
          }
          break;
        default:
          console.log('Unknown fruit');
      }  
    },
    clickHandle(ind) {
      if (ind == 0) {
        window.location.href = 'http://demo.lvluoxinli.com/demo/xsjzEducationPc/index.html#/'
      } else if (ind == 1) {
        window.location.href = 'http://demo.lvluoxinli.com/demo/teacherPC20/index.html#/'
      } else if (ind == 2) {
        window.location.href = 'http://demo.lvluoxinli.com/demo/stuPC/index.html#/'
      } else {
        window.location.href = 'https://mt-demo.lvluoxinli.com/medical/index.html'
      }
    }
   }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.MainView {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  background-color: #FFFFFF;
}
.MainView .remark{
  position: absolute;
  left: 50%;
  bottom: 15px;
  font-size:14px;
  color:rgba(153,153,153,1);
  line-height:14px;
  text-align: center;
  margin-left: -314px;
}
.remark>p{
  padding-bottom: 15px;
  font-size: 14px!important;
  display: block;
  -webkit-margin-before: 0px;
  margin-block-start: 0px;
  -webkit-margin-after: 0px;
  margin-block-end: 0px;
  -webkit-margin-start: 0px;
  margin-inline-start: 0px;
  -webkit-margin-end: 0px;
  margin-inline-end: 0px;
}
.MainView .con_left {
  width: 460px;
  height: auto;
  padding: 40px;
  box-sizing: border-box;
  background-color: #EAF3CA;
  position: relative;
  display: flex;
  align-items: center;
}
.MainView .con_left .login_icon {
  width: 140px;
  height: auto;
  position: absolute;
  top: 40px;
}
.MainView h2, .MainView h4, .MainView .con_left .left_box ul{
  margin: 0px;
  padding: 0px;
}
.MainView .con_left .left_box {
  width: 100%;
  font-size: 16px;
  line-height: 16px;
  color: #19592F;
  font-family: PingFangSC, PingFang SC;
}
.MainView .con_left h2, .MainView .con_left .left_box > span, .MainView .con_right h2 {
  color: #36AE5A;
  font-size: 32px;
  line-height: 32px;
}
.MainView .con_left .left_box > span {
  display: block;
  font-weight: 600;
  color: #19592F;
  margin: 8px 0px;
}
.MainView ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: 0px;
}
.MainView .con_left .left_box ul li {
  width: 182px;
  list-style: none;
  padding: 24px;
  margin-top: 16px;
  background: linear-gradient(180deg, #FFFFF7 0%, #FFFFFF 100%);
  box-shadow: 0px 2px 16px 0px rgba(30,57,34,0.08);
  border-radius: 16px;
  box-sizing: border-box;
}
.MainView .con_left .left_box ul li:nth-child(odd) {
  margin-right: 16px;
}
.MainView .con_left .left_box ul li img {
  height: 48px;
  width: auto;
  margin: 29px 0px 8px;
}
.MainView .con_left .left_box ul li h4 {
  font-weight: 400;
}
.MainView .con_left .left_box ul li span {
  display: block;
  color: #19592F;
  font-weight: bold;
}
.MainView .con_left .left_foot {
  display: flex;
  align-items: center;
  margin-top: 24px;
}
.MainView .con_left .left_foot img {
  width: 16px;
  height: auto;
  margin-right: 11px;
}
.MainView .con_left .left_foot span {
  color: #19592F;
  font-size: 12px;
  line-height: 12px;
  font-family: PingFangSC, PingFang SC;
}
.MainView .con_right {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
  position: relative;
}
.MainView .con_right .right_warp {
  font-size: 14px;
  line-height: 14px;
  color: #8C8C8C;
  font-family: PingFangSC, PingFang SC;
}
.MainView .con_right h2 {
  color: #262626;
  line-height: 32px;
  text-align: center;
}
.MainView .con_right .right_warp .title {
  display: block;
  text-align: center;
}
.MainView .con_right .right_warp ul {
  display: flex;
  flex-wrap: wrap;
  width: 550px;
}
.MainView .con_right .right_warp li {
  list-style: none;
  width: 260px;
  padding: 24px;
  box-sizing: border-box;
  border-radius: 16px;
  border: 1px solid #E9E9E9;
  text-align: center;
  transition: all 0.4s;
  transform: translateY(0px);
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}
.MainView .con_right .right_warp li:hover {
  transform: translateY(-20px);
  box-shadow: 0px 4px 20px 0px rgba(69,76,80,0.08);
}
.MainView .con_right .right_warp li:last-child {
  margin-right: 0px;
}
.MainView .con_right .right_warp li img {
  width: 64px;
  height: auto;
}
.MainView .con_right .right_warp li h4 {
  font-size: 18px;
  line-height: 24px;
  margin-left: 39px;
}
.MainView .con_right .right_warp li:first-child h4 {
  color: #5582BA;
}
.MainView .con_right .right_warp li:nth-child(2) h4 {
  color: #56BA79;
}
.MainView .con_right .right_warp li:nth-child(3) h4 {
  color: #EEAA4D;
}
.MainView .con_right .right_warp li:last-child h4 {
  color: #B256BA;
}
/* .logo{
  padding-left: 40px;
  padding-top: 40px;
}
.logo img{
  width:177px;
  object-fit:contain;
}
.MainView .content{
  position: absolute;
  left: 50%;
  top:50%;
  transform: translate(-50%, -50%);
}
.greeting{
  text-align: center;
  font-size: 32px;
}

.choose{
  font-size: 14px;
  text-align: center;
  padding-top: 14px;
  color: #999999;
}
.main{
  display: flex;
  padding-top: 40px;
  justify-content: center;
}
.login img{
  height: 320px;
  width: 230px;
  margin-left: 10px;
  margin-right: 10px;
  transition: all 0.4s;
  transform: translateY(0px);
  cursor: pointer;
}
.login img:hover {
  transform: translateY(-40px);
  box-shadow:0 7px 10px rgba(0, 0, 0, .20);
}
*/
.jgwablogo{
  width: 16px;
  height: 16px;
  display: inline-block;
  background: url('../assets/jgwab.png');
  position: relative;
  top: 3px;
}
@media (max-width: 1200px){
  .MainView .con_left {
    display: none;
  }
}
@media (min-width: 569px) {
  .MainView {
    min-height: 770px;
  }
}
</style>
